// import AuthService from '../../../AuthService.js';

// import {
//   createSlice,
//   createAsyncThunk
// } from '@reduxjs/toolkit';

// let authService = new AuthService();
// const initialState = {
//   groups: [],  
//   groupusers: [],
//   isGroupLoading: false,
//   errorMessage: undefined,
//   successMessage: undefined,
//   selectedGroup: undefined,
//   selectedAction: '',
//   isLoading:false,
//   accountusers: [],
//   isAddingGroup: false,
//   isAddSubmitted: false,
//   isEditGroup: false,
//   isEditSubmitted: false
// };

// export const fetchAllGroupsList = createAsyncThunk (
//     'groups/fetchAllGroupsList',
//     async ({userId,accountValue}) => {
//       if(userId !== undefined && accountValue !== undefined){
//         let url = "getGroupsByAccountId/"+accountValue+"/"+userId;
//         const response = await authService.fetch(url,{ method: 'GET'})
//         const data = await response.json();
//         if (response.status < 200 || response.status >= 300) {
//             return response.status
//         }      
//         return data;    
//       }
//     }
// )

// export const fetchAllGroupUsers = createAsyncThunk(
//     'groups/fetchAllGroupUsers',
//     async () => {
//         let url = "getusersByGroups/";
//         const response = await authService.fetch(url,{ method: 'GET'})
//         const data = await response.json();
//         if (response.status < 200 || response.status >= 300) {
//             return response.status
//         }      
//         return data;
//     }
// );

// export const addNewGroupToList =  createAsyncThunk(
//     'groups/addNewGroupToList',
//     async (groupDetails) => {
//       if(groupDetails !== undefined){
//         console.log('groupdetails',groupDetails)
//         let url = "addgroup/";
//         const response = await authService.fetch(url, {method: 'POST', body: JSON.stringify(groupDetails)})
//         const data = await response.json();
//         if (response.status < 200 || response.status >= 300) {
//             return response.status
//         }
//         else if (data) {
//             return {
//                 ...groupDetails,
//                 groupid: data,
//                 users: []            
//             }
//         }
//         return "The addgroup API didn't return a response";        
//       }
//     }
// );

// export const deleteGroupFromList = createAsyncThunk( 
//     'groups/deleteGroupFromList',
//     async (groupValue) => {
//       if(groupValue !== undefined){
//         let url = "deletegroup/" + groupValue;
//         const response = await authService.fetch(url, {method: 'DELETE'})
//         const data = await response.json();
//         if (response.status < 200 || response.status >= 300) {
//             return response.status
//         }
//         return {
//             message: data,
//             groupid: groupValue
//         };        
//       }
//     }
// );

// export const getAccountUsers = createAsyncThunk(
//     'groups/getAccountUsers',
//     async accountValue => {
//       if(accountValue !== undefined){
//         let fetchString = 'getUsersByAccountIdForDropdown/';
//         fetchString += accountValue; //by account id
//         const response = await authService.fetch(fetchString,{ method: 'GET'})
//         const data = await response.json();
//         if (response.status < 200 || response.status >= 300) {
//             return response.status
//         }   
//         const userNames = data.filter(e => (e.isadmin && e.isexpert) || !e.isadmin)   
//         return userNames;        
//       }
//     }  
// );

// export const editGroupFromList = createAsyncThunk(
//     'groups/editGroupFromList',
//     async (groupDetails) => {
//       if(groupDetails !== undefined){
//         let url = "updateGroup/";
//         const response = await authService.fetch(url, {method: 'PUT', body: JSON.stringify(groupDetails)})
//         const data = await response.json();
//         if (response.status < 200 || response.status >= 300) {
//             return response.status
//         }
//         return data;        
//       }
//     }
// );

// const groupsSlice = createSlice({
//     name: "groups",
//     initialState,
//     reducers: {
//         updateSelectedGroup(state, action){
//           state.selectedGroup = action.payload.id;
//           state.selectedAction = action.payload.action;
//         },
//         updateEditSubmitted(state, action){
//           state.isEditSubmitted = action.payload;
//         }
//     },
//     extraReducers: (builder) => {
//       builder.addCase(fetchAllGroupsList.pending, (state) => {
//         state.isGroupLoading = true;
//       }),
//       builder.addCase(fetchAllGroupsList.fulfilled, (state, action) => {
//         state.groups = action.payload;
//         state.isGroupLoading = false;
//       }),    
//       builder.addCase(fetchAllGroupsList.rejected, (state, action) => {
//         state.isGroupLoading = false;
//         state.errorMessage = action.payload;
//       });
//       builder.addCase(fetchAllGroupUsers.pending, (state) => {
//         state.isGroupUserLoading = true;
//       }),
//       builder.addCase(fetchAllGroupUsers.fulfilled, (state, action) => {
//         state.groupusers = action.payload;
//         state.isGroupUserLoading = false;
//       });
//       builder.addCase(fetchAllGroupUsers.rejected, (state, action) => {
//         state.isGroupUserLoading = false;
//         state.errorMessage = action.payload;
//       }),
//       builder.addCase(addNewGroupToList.pending, (state) => {
//         state.isAddingGroup = true;
//       }),
//       builder.addCase(addNewGroupToList.fulfilled, (state, action) => {
//         state.groups = [...state.groups, action.payload];
//         state.isAddingGroup = false;
//         state.isAddSubmitted = true;
//       }),
//       builder.addCase(addNewGroupToList.rejected, (state, action) => {
//         state.isAddingGroup = false;
//         state.errorMessage = action.payload;
//       }),
//       builder.addCase(deleteGroupFromList.pending, (state) => {
//         state.isLoading = true;
//       }),
//       builder.addCase(deleteGroupFromList.fulfilled, (state, action) => {      
//         const updatedGroupList = state.groups.filter((group)=> group.groupid !== action.payload.groupid);
//         //console.log(updatedGroupList.length);
//         state.groups = updatedGroupList;
//         state.successMessage = action.payload.message;
//         state.isLoading = false;        
//       }),
//       builder.addCase(deleteGroupFromList.rejected, (state, action) => {
//         state.successMessage = undefined;
//         state.errorMessage = action.payload;
//         state.isLoading = false;  
//       }),
//       builder.addCase(editGroupFromList.pending, (state) => {
//         state.isEditGroup = true;
//       }), 
//       builder.addCase(editGroupFromList.fulfilled, (state, action) => { 
//        const index = state.groups.findIndex(group=> group.groupid === action.payload.groupid);
//        let finalGroupList = [...state.groups];
//        finalGroupList[index] = action.payload;       
//        state.groups = finalGroupList;        
//        state.isEditSubmitted = true;
//        state.isEditGroup = false;       
//       }),
//       builder.addCase(editGroupFromList.rejected, (state, action) => {
//         state.errorMessage = action.payload;
//         state.isEditGroup = false;
//       }),             
//       builder.addCase(getAccountUsers.fulfilled, (state, action) => {
//           state.accountusers = action.payload
//       })
//       builder.addCase(getAccountUsers.rejected, (state, action) => {
//           state.errorMessage = action.payload;
//       })    
//     }
// });

// export default groupsSlice.reducer;
// export const { updateSelectedGroup, updateEditSubmitted } = groupsSlice.actions
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { admin_api } from "../../../API/services/admin";
import { current } from '@reduxjs/toolkit'

const initialState = {
  groups:[],
  group_name: '',
  group_description: '',
  group_users:[],
  isLoading:false,
  error:null,
  success:false,
  isGroupLoading:false,
  selectedGroup: undefined,
  selectedAction: '',
  isEditSubmitted: false,
  incomingUser: '',
  incomingSource: '',
  incomingGroup: null
};

export const addGroup = createAsyncThunk(
  'groups/addNewGroupToList',
  async (groups,{rejectWithValue} ) => {
    try{
      const token = await getTokenFromSession();
      const response = await axios.post(admin_api.addGroup.url, groups,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllGroupsList = createAsyncThunk(
  'groups/fetchAllGroupsList',
  async (_,{rejectWithValue} ) => {
    try{
      const token = await getTokenFromSession();
      const response = await axios.get(admin_api.getAllgroups.url,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log('res1',response);
      return response.data;
      
    }catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteGroupFromList = createAsyncThunk(
  'groups/deleteGroupFromList',
  async (groupId,{rejectWithValue} ) => {
    //console.log(groupId);
    try{
      const token = await getTokenFromSession();
      const response = await axios.delete(admin_api.deleteGroup.url(groupId),
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log('response',response.data)
      return response.data;
    }catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editGroupFromList = createAsyncThunk(
  'groups/getAccountUsers',
  async (groups,{rejectWithValue} ) => {
    try{
      const token = await getTokenFromSession();
      const response = await axios.put(admin_api.updateGroup.url, groups,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addGroupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    updateIncomingCallUserDetails(state, action) {
      state.incomingUser = action.payload.email;
      state.incomingSource = action.payload.source;
    },
    updateIncomingCallGroupDetails(state, action) {
      state.incomingGroup = action.payload.group_id;
    },
    updateSelectedGroup(state, action){
      state.selectedGroup = action.payload.id;
      state.selectedAction = action.payload.action;
    },
    updateEditSubmitted(state, action){
      state.isEditSubmitted = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addGroup.fulfilled, (state, action) => {
        let old_groups = [...current(state.groups.groups)];
        state.isLoading = false;
        let newpayload = Object.assign({},action.payload.group);
        newpayload['group_id'] = action.payload.id;
        newpayload['group_users'] = action.payload.group_users;
        old_groups.push(newpayload);
        state.groups.groups = old_groups; 
        state.success = action.payload.success;
      })
      .addCase(addGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.success = action.payload.success;
      })
      .addCase(fetchAllGroupsList.pending, (state) => {
        state.isGroupLoading = true;
      })
      .addCase(fetchAllGroupsList.fulfilled, (state, action) => {
        state.groups = action.payload;
        state.isGroupLoading = false;
      })   
      .addCase(fetchAllGroupsList.rejected, (state, action) => {
        state.isGroupLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(deleteGroupFromList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteGroupFromList.fulfilled, (state, action) => {     
        let currentgrps = [...current(state.groups.groups)] 
        const updatedGroupList = currentgrps.filter((group)=> group.group_id !== action.payload.deletedGroup[0].id);
        state.groups.groups = updatedGroupList;
        state.successMessage = action.payload.message;
        state.isLoading = false;        
      })
      .addCase(deleteGroupFromList.rejected, (state, action) => {
        state.successMessage = undefined;
        state.errorMessage = action.payload;
        state.isLoading = false;  
      })
      .addCase(editGroupFromList.pending, (state) => {
        state.isEditGroup = true;
      })
      .addCase(editGroupFromList.fulfilled, (state, action) => { 
        console.log('pa',action.payload)
        if(action.payload.group !==undefined){
          let currentgrps = [...current(state.groups.groups)]
          const index = currentgrps.findIndex(group=> group.group_id === action.payload.group[0].id);
          let finalGroupList = [...currentgrps];
          let newpayload = Object.assign({},finalGroupList[index]);
          newpayload['group_name'] = action.payload.group[0].group_name;
          newpayload['group_description'] = action.payload.group[0].group_description;
          //action.payload.group_users!==undefined && action.payload.group_users.map(x=>newpayload.group_users.push({'first_name':x.}));
          newpayload['group_users'] = action.payload.group_users;
          finalGroupList[index] = newpayload;    
          console.log('newpayload',finalGroupList)   
          state.groups.groups = finalGroupList;        
          state.isEditSubmitted = true;
          state.isEditGroup = false; 
        }      
      })
      .addCase(editGroupFromList.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.isEditGroup = false;
      });
  },
});

//export const { clearItems } = addGroupSlice.actions;
export default addGroupSlice.reducer;
export const { updateSelectedGroup, updateEditSubmitted, updateIncomingCallUserDetails, updateIncomingCallGroupDetails } = addGroupSlice.actions


