import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    chats:[],
    isLoading:false,
    isUpdating:false
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    AddToChat: (state, action) => {
        console.log('payload',action.payload);
        console.log('chats',state.chats);
        let allchat = [...state.chats];
        console.log('all',allchat)
        allchat.push(action.payload)
        state.chats = allchat;
    },
    FetchChat: (state, action) => {
      state.chats;
    },
    UpdateChat: (state,action) => {
        let allchat = [...state.chats]
        const index = allchat.findIndex(obj => obj.MsgId === action.payload.MsgId);
        allchat[index] = action.payload;
        state.chats = allchat
    },
  },
});

// Export actions
export const { AddToChat, FetchChat, UpdateChat } = chatSlice.actions;

// Export the reducer
export default chatSlice.reducer;