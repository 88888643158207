let backend_domain = window._env_.REACT_APP_API_URL;
let api_version = window._env_.REACT_APP_API_VERSION;

//knowledge capture api's
export const kc_api = {
  getAllKC: {
    method: "GET",
    url: `${backend_domain}${api_version}/wf/get-all-kc`,
    headers: { "Content-Type": "application/json" },
  },
  updateKC: {
    method: "PATCH",
    url: `${backend_domain}${api_version}/wf/edit-kc`,
    headers: { "Content-Type": "application/json" },
  },
  postKC: {
    method: "POST",
    url: `${backend_domain}${api_version}/wf/add-kc`,
    headers: { "Content-Type": "application/json" },
  },
  kcToTemplate: {
    method: "POST",
    url: `${backend_domain}${api_version}/wf/kc-to-template`,
    headers: { "Content-Type": "application/json" },
  },
};
