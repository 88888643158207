import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { workflow_api } from "../../../API/services/workflow";

const initialState = {
  taskCategories: [],
  taskEvidences: [],
  loading: false,
  error: null,
  loadingEdit: false,
  errorEdit: null,
  message: null,
};


//task category api
export const getTaskCategories = createAsyncThunk("view/fetchTaskCategories", async (_, { rejectWithValue }) => {
  try {
    // console.log("gettaskapicall");
    const token = await getTokenFromSession();
    const response = await axios.get(`${workflow_api.getTaskCategories.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

//task evidence api
export const getTaskEvidences = createAsyncThunk("view/fetchTaskEvidences", async (_, { rejectWithValue }) => {
  try {
    // console.log("gettaskapievidences");
    const token = await getTokenFromSession();
    const response = await axios.get(`${workflow_api.getTaskEvidences.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const getWorkflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //task category
      .addCase(getTaskCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTaskCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.taskCategories = action.payload;
      })
      .addCase(getTaskCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //task evidences
      .addCase(getTaskEvidences.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTaskEvidences.fulfilled, (state, action) => {
        state.loading = false;
        state.taskEvidences = action.payload;
      })
      .addCase(getTaskEvidences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
      
  },
});

export default getWorkflowSlice.reducer;
