let backend_domain = window._env_.REACT_APP_API_URL;
let api_version = window._env_.REACT_APP_API_VERSION;

//work flow api's
export const workflow_api = {
  getTaskCategories: {
    method: "GET",
    url: `${backend_domain}${api_version}/wf/get-task-category`,
    headers: { "Content-Type": "application/json" },
  },
  getTaskEvidences: {
    method: "GET",
    url: `${backend_domain}${api_version}/wf/get-task-evidence`,
    headers: { "Content-Type": "application/json" },
  },

  //api is used to create template and jobs
  createWorkflow: {
    method: "POST",
    url: `${backend_domain}${api_version}/wf/add-job`,
    headers: { "Content-Type": "'multipart/form-data'" },
  },
  getAllWorkflow: {
    method: "POST",
    url: `${backend_domain}${api_version}/wf/get-all-job`,
    headers: { "Content-Type": "application/json" },
  },
  getAllWorkflowDetail: {
    method: "POST",
    url: `${backend_domain}${api_version}/wf/get-job-details`,
    headers: { "Content-Type": "application/json" },
  },

  editTemplate: {
    method: "PUT",
    url: `${backend_domain}${api_version}/wf/edit-job`,
    headers: { "Content-Type": "application/json" },
  },
  deleteWorkflow: {
    method: "DELETE",
    url: `${backend_domain}${api_version}/wf/delete-job`,
    headers: { "Content-Type": "application/json" },
  } 
};
