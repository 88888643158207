// API/services/assignee.js

export const getAssigneesApiUrl = (assigneeType) => {
  const backend_domain = window._env_.REACT_APP_API_URL;
  const api_version = window._env_.REACT_APP_API_VERSION;

  return {
    method: "GET",
    url: `${backend_domain}${api_version}/wf/get-assignees-list?assignees=${assigneeType}`,
    headers: { "Content-Type": "application/json" },
  };
};

export const assignJobApiUrl = (jobId, assigneeId) => {
  const backend_domain = window._env_.REACT_APP_API_URL;
  const api_version = window._env_.REACT_APP_API_VERSION;

  return {
    method: "POST",
    url: `${backend_domain}${api_version}/wf/assign-job`,
    headers: { "Content-Type": "application/json" },
    data: {
      job_id: jobId,
      assignee_id: assigneeId,
    },
  };
};
