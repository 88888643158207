
import * as decode from 'jwt-decode';
import { printConsole } from './helpers';

export default class AuthService {
    // Initializing important variables
    constructor() {

        if (AuthService.instance) {
            printConsole("AuthService already exists....");
            return AuthService.instance;
        }

        AuthService.instance = this;
        //console.log("window._env_",window._env_)

        this.domain = window._env_.REACT_APP_API_URL; // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    login(options) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/signin`, {
            method: 'POST',
            body: JSON.stringify(options)
        }).then(res => {
            this.setToken(res.token) // Setting the token in sessionStorage
            this.setUsers(res) // Setting the token in sessionStorage
            return Promise.resolve(res);
        })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    signout(userId, closeWindow = false) {
        fetch(window._env_.REACT_APP_API_URL + '/signout', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: userId
            })
        })
            .then(() => {
                //console.log("logout successful....",res);
                this.logout();
                closeWindow ? window.close() : window.location = '/';//TP-671
            })
            .catch(e => {
                console.log("error in signout::: ", e);
            });
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);

            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                /** If user token is expired then call the signout backend api to update the 
                    *  User to is_loggedin: false
                   */
                decoded.user && this.signout(decoded.user.id);
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to sessionStorage
        sessionStorage.setItem('user_token', idToken)
    }

    setUsers(usersData) {
        // Saves user data to sessionStorage
        sessionStorage.setItem('temp_users', JSON.stringify(usersData));
    }

    getToken() {
        // Retrieves the user token from sessionStorage
        return sessionStorage.getItem('user_token')
    }

    logout() {
        printConsole("Logging out the user")
        // Clear user token and profile data from sessionStorage
        sessionStorage.removeItem('user_token');
        sessionStorage.removeItem('pageName'); //TP-1579
    }

    async getProfile() {
        // Using jwt-decode npm package to decode the token
        return this.fetch('getUserByToken', {});
        // return decode(this.getToken());
    }


    fetch(url, options, headers) {
        // performs api calls sending the required authentication headers
        if (!headers) {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }
        return fetch(`${this.domain}/${url}`, {
            headers,
            ...options
        })
            .then(this._checkStatus)
        //TP-5273,5710
        // .catch(error => {
        //     printConsole(`error is authservice fetch ${error}`)//TP-4942
        //     return error;
        // })
        // .then(response => response.json())
    }

    _checkStatus(response) {
        if (response.status === 401) {
            sessionStorage.removeItem('user_token');
            sessionStorage.removeItem('pageName'); //TP-1579
            window.location = '/';
            return;
        }
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            // var error = new Error(response.statusText)
            // error.response = response
            throw response;
        }
    }

    getDecodedUser() {
        const token = this.getToken() // GEtting token from localstorage
        const { user } = decode(token);
        return user;
    }
}