import AuthService from "../../../AuthService.js";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTokenFromSession } from "../../../helpers.js";
import { workflow_api } from "../../../API/services/workflow.js";
import axios from "axios";

let authService = new AuthService();
const initialState = {
  templates: [],
  checklistTaskData: [],
  wiTaskData: [],
  wiTaskDataActions: [],
  wiTaskDataEvidences: [],
  isActionsFetching: false,
  isFetching: false,
  isTaskFetching: false,
  errorMessage: undefined,
  isAssetFetching: false,
  allAssets: [],
  isThemeLoading: false,
  themes: [],
  isFetchingAsset: false,
  assetDetails: [],
  isLocationFetching: false,
  allLocations: [],
  selectedTemplate: undefined,
  selectedAction: "",
  isJobExists: false,
  isJobReferenceIdExists: false,
  incidents: [],
};

export const fetchAllChecklistThemes = createAsyncThunk(
  "templates/fetchAllChecklistThemes",
  async () => {
    let url = "jobs/getCheckListThemes/";
    const response = await authService.fetch(url, { method: "GET" });
    const data = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return response.status;
    } else return data;
  }
);

export const fetchAllCheckListTasks = createAsyncThunk(
  "templates/fetchAllCheckListTasks",
  async (templateValue) => {
    if (templateValue !== undefined) {
      let url = "jobs/getAllCheckListTasks/" + templateValue;
      const response = await authService.fetch(url, { method: "GET" });
      const data = await response.json();
      if (response.status < 200 || response.status >= 300) {
        return response.status;
      } else return data;
    }
  }
);

export const fetchAssets = createAsyncThunk(
  "templates/fetchAssets",
  async () => {
    let url = "jobs/getAssetsOfAccount/";
    const response = await authService.fetch(url, { method: "GET" });
    const data = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return response.status;
    } else if (data && data !== null) return data;
    else return "The fetchAssets API didn't return a response";
  }
);

export const fetchLocation = createAsyncThunk(
  "templates/fetchLocation",
  async () => {
    let url = "jobs/getLocations/";
    const response = await authService.fetch(url, { method: "GET" });
    const data = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return response.status;
    } else if (data && data !== null) return data;
    else return "The fetchLocation API didn't return a response";
  }
);

export const fetchAllSubTaskActions = createAsyncThunk(
  "templates/fetchAllSubTaskActions",
  async () => {
    let url = "jobs/getSubTaskActions/";
    const response = await authService.fetch(url, { method: "GET" });
    const data = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return response.status;
    } else if (data && data !== null) return data;
    else return "The fetchAllSubTaskActions API didn't return a response";
  }
);

export const fetchAllSubTasks = createAsyncThunk(
  "templates/fetchAllSubTasks",
  async (templateValue) => {
    let url = "jobs/getAllSubTasks/";
    url += templateValue;
    const response = await authService.fetch(url, { method: "GET" });
    const data = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return response.status;
    } else if (data && data !== null) return data;
    else return "The fetchAllSubTasks API didn't return a response";
  }
);

export const fetchIndividualAsset = createAsyncThunk(
  "templates/fetchIndividualAsset",
  async ({ assetValue, accountValue }) => {
    let url = "jobs/getAssetDetails";
    const response = await authService.fetch(url, {
      method: "post",
      body: JSON.stringify({ id: assetValue, account_id: accountValue }),
    });
    const data = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return response.status;
    } else if (data && data !== null) return data;
    else return "The fetchIndividualAsset API didn't return a response";
  }
);

/* export const checkJobExists = createAsyncThunk(
  'templates/checkJobExists',
  async ({jobName, jobField, jobId}) => {
    console.log(jobField);
    let url = 'jobs/checkJobExists/';
    url += jobName;
    if(jobId){
      url += `/${jobId}`;//T32-197, pass the job id so that it can be passed to the query. This is optional and only used for edit
    }
    const response = await authService.fetch(url, {method: 'GET'})
    const data = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return response.status
    }
    else if (data && data !== null)
      return data;
    else
      return false;
  }
)

export const checkReferenceIdExists = createAsyncThunk(
  'templates/checkReferenceIdExists',
  async ({referenceId, jobField, jobId}) => {
    console.log(jobField);
    let url = 'jobs/checkReferenceIdExists/';
    url += referenceId;
    if(jobId){
      url += `/${jobId}`;//T32-197, pass the job id so that it can be passed to the query. This is optional and only used for edit
    }
    const response = await authService.fetch(url, {method: 'GET'})
    const data = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return response.status
    }
    else if (data && data !== null)
      return data;
    else
      return false;
  }
) */


const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    /**This is a reducer for storing the templatedata from the dummy JSON
     * To be removed after API integration
     */
    addJobsFromJson(state, action) {
      state.templates = action.payload;
    },
    addIncidentsFromJson(state, action) {
      //state.incidents = [...state.incidents, action.payload.incidents];
      //console.log(action.payload);
      let incArr = [];
      action.payload.forEach((job) => {
        if (job)
          job.incidents.forEach((insidence) => {
            incArr.push(insidence);
          });
      });
      //console.log(incArr)
      state.incidents = incArr;
    },
    updateSelectedTemplate(state, action) {
      state.selectedTemplate = action.payload.id;
      state.selectedAction = action.payload.action;
    },
    updateSelectedIncident(state, action) {
      state.selectedIncident = action.payload.id;
      state.selectedAction = action.payload.action;
    },
    addcategoryfromJson(state, action) {
      state.isActionsFetching = true;
      state.wiTaskDataActions = action.payload;
      state.isActionsFetching = false;
    },
    addevidencefromJson(state, action) {
      state.wiTaskDataEvidences = action.payload;
    },
    /* updateEditSubmitted(state, action){
          state.isEditSubmitted = action.payload;
        } */
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllChecklistThemes.pending, (state) => {
      state.isThemeLoading = true;
    }),
      builder.addCase(fetchAllChecklistThemes.fulfilled, (state, action) => {
        state.themes = action.payload;
        state.isThemeLoading = false;
      }),
      builder.addCase(fetchAllChecklistThemes.rejected, (state, action) => {
        state.isThemeLoading = false;
        state.errorMessage = action.payload;
      });
    builder.addCase(fetchAllCheckListTasks.pending, (state) => {
      state.isTaskFetching = true;
    }),
      builder.addCase(fetchAllCheckListTasks.fulfilled, (state, action) => {
        state.checklistTaskData = action.payload;
        state.isTaskFetching = false;
      }),
      builder.addCase(fetchAllCheckListTasks.rejected, (state, action) => {
        state.isTaskFetching = false;
        state.errorMessage = action.payload;
      });
    builder.addCase(fetchAssets.pending, (state) => {
      state.isAssetFetching = true;
    }),
      builder.addCase(fetchAssets.fulfilled, (state, action) => {
        const filteredAssets = action.payload.filter(
          (asset) => asset.asset_class_name
        ); //TP-2580
        state.allAssets = filteredAssets;
        state.isAssetFetching = false;
      }),
      builder.addCase(fetchAssets.rejected, (state, action) => {
        state.isAssetFetching = false;
        state.errorMessage = action.payload;
      });
    builder.addCase(fetchLocation.pending, (state) => {
      state.isLocationFetching = true;
    }),
      builder.addCase(fetchLocation.fulfilled, (state, action) => {
        state.allLocations = [...state.allLocations, action.payload];
        state.isLocationFetching = false;
      }),
      builder.addCase(fetchLocation.rejected, (state, action) => {
        state.isLocationFetching = false;
        state.errorMessage = action.payload;
      });
    builder.addCase(fetchAllSubTaskActions.pending, (state) => {
      state.isActionsFetching = true;
    }),
      builder.addCase(fetchAllSubTaskActions.fulfilled, (state, action) => {
        let subTaskActions = action.payload.filter(
          (action) => action.command !== "Ctrl+A"
        ); //MB2-892
        state.wiTaskDataActions = subTaskActions;
        state.isActionsFetching = false;
      }),
      builder.addCase(fetchAllSubTaskActions.rejected, (state, action) => {
        state.isActionsFetching = false;
        state.errorMessage = action.payload;
      });
    builder.addCase(fetchAllSubTasks.pending, (state) => {
      state.isTaskFetching = true;
    }),
      builder.addCase(fetchAllSubTasks.fulfilled, (state, action) => {
        state.wiTaskData = action.payload;
        state.isTaskFetching = false;
      }),
      builder.addCase(fetchAllSubTasks.rejected, (state, action) => {
        state.isTaskFetching = false;
        state.errorMessage = action.payload;
      });
    builder.addCase(fetchIndividualAsset.pending, (state) => {
      state.isFetchingAsset = true;
    }),
      builder.addCase(fetchIndividualAsset.fulfilled, (state, action) => {
        state.assetDetails = [action.payload];
        state.isFetchingAsset = false;
      }),
      builder.addCase(fetchIndividualAsset.rejected, (state, action) => {
        state.isTaskFetching = false;
        state.isFetchingAsset = action.payload;
      });
    /*  builder.addCase(checkJobExists.fulfilled, (state, action) => {              
        state.isJobExists = action.payload;
      });
      builder.addCase(checkReferenceIdExists.fulfilled, (state, action) => {              
        state.isJobReferenceIdExists = action.payload;
      }); */

  },
});

export default templatesSlice.reducer;
export const {
  addJobsFromJson,
  addIncidentsFromJson,
  updateSelectedTemplate,
  updateSelectedIncident,
  addcategoryfromJson,
  addevidencefromJson,
} = templatesSlice.actions;
