import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTokenFromSession } from '../../../helpers.js';
import { getAssigneesApiUrl, assignJobApiUrl } from '../../../API/services/assignee.js';

const initialState = {
  assigneesData: [],
  loading: false,
  error: null,
  assignJobStatus: null,
};

// fetchAssignees Thunk
export const fetchAssignees = createAsyncThunk(
  'assignees/fetchAssignees',
  async (assigneeType, { rejectWithValue }) => {
    try {
      const token = await getTokenFromSession();
      const { url, method, headers } = getAssigneesApiUrl(assigneeType);
      const response = await axios({
        method,
        url,
        headers: {
          ...headers,
          "Authorization": `Bearer ${token}`
        }
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// assignJob Thunk
export const assignJob = createAsyncThunk(
  'assignees/assignJob',
  async ({ jobId }, { rejectWithValue }) => {
    try {
      const token = await getTokenFromSession();
      const { url, method, headers, data } = assignJobApiUrl(jobId);
      const response = await axios({
        method,
        url,
        headers: {
          ...headers,
          "Authorization": `Bearer ${token}`,
        },
        data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const assigneeSlice = createSlice({
  name: 'assigneeInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignees.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAssignees.fulfilled, (state, action) => {
        state.loading = false;
        state.assigneesData = action.payload;
      })
      .addCase(fetchAssignees.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle assignJob cases
      .addCase(assignJob.pending, (state) => {
        state.assignJobStatus = 'loading';
        state.error = null;
      })
      .addCase(assignJob.fulfilled, (state) => {
        state.assignJobStatus = 'success';
      })
      .addCase(assignJob.rejected, (state, action) => {
        state.assignJobStatus = 'failed';
        state.error = action.payload;
      });
  },
});

export default assigneeSlice.reducer;
